:root {
    --card_width: 380px;
    --row_increment: 10px;
    --card_medium: 50;
    --card_large: 50;
}

.App {
    padding-bottom: 50px;
    margin-top: 120px
}

.navbar {
    background: white;
}

.navbar .navbar-brand img {
    height: 80px;
}

#navbar-buttons {
    font-size: larger;
    font-weight:500;
}

#navbar-buttons a {
    border-radius: 3mm;
    background-color: #0078c1; 
    color: white
}

#navbar-buttons a:hover {
    color: white;
    background-color: #FEB21B;
}

#navbar-buttons a.dropdown-item {
    background-color: white;
    color: black
}

#navbar-buttons a.dropdown-item:hover {
    background-color: #c2e3f8;
}

#navbar-buttons li {
    margin-left: 2rem;
}

#navbar-right .nav-item {
    min-width: 85px;
    text-align: center;
}



.login-button {
    background-color: rgb(255, 59, 59) !important;
    color: white !important;

}

.user-avatar {
    font-size: 20px;
    color: #167bba
}

.modal-head {
    text-align: center;
    padding: 20px
}

.modal-logo {
    text-align: center;
    margin-bottom: 20px;
}

.modal-logo .btn-close {
    position: absolute;
    right: 10px
}

.modal-body {
    width: 350px;
    margin-left: auto;
    margin-right: auto
}

.error-message {
    color:rgb(255, 59, 59)
}

.mapbox-map {
    position: relative;
    margin: 0 60px;
    border-radius: 3mm;
    overflow: hidden;
    z-index: 0
}

.map-anchor {
    height: 1px;
    width: 1px;
    position: relative;
    top: -120px;
    background-color: transparent;
}

.popup-distance {
    font-weight: bold;
}


.sidebar {
    width: 400px;
    position: absolute;
    z-index: 1;
    top: 20px;
    left: -500px;
    bottom: 20px;
    padding: 15px;
    background-color: #fffef8;
    border-radius: 10px;
    transition: 0.5s;
    font-size: 15px;
}

.sidebar.show {
    left: 20px;
}

.sidebar .sidebar-inner {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

.sidebar .close-sidebar {
    position: absolute;
    right: -30px;
    border-radius: 0 10px 10px 0;
    top: 50%;
    transform: translateY(-50%);
    background: white;
    padding: 10px;
    cursor: pointer;
}

.detailed-info .row {
    margin-bottom: 20px;
}

.detailed-info .label {
    font-weight: bold;
}

.review-content {
    margin-top: 20px;
}

.search-bar input {
    border-radius: 5px 0 0 5px;
    border-right: none
}

.search-bar .dropdown-menu {
    top: 40px;
    left: 0;
    right: 0
}

.nav-pills {
    font-size: 15px;
    font-weight: 500;
}

.nav-pills .nav-link.active {
    background-color: #0078c1;
}

.news-header {
    height: 130px;
    margin-top: 100px;
    padding-top: 40px;
    padding-left: 150px;
    background-color: #0078c1;
    color: white;
    font-family: sans-serif;
    text-transform: uppercase;
    letter-spacing: 5px;
}

.pin_container {
    top: 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, var(--card_width));
    grid-auto-rows: var(--row_increment);
    justify-content: center;
    background-color: #E2F2F9;
  
}

.pin_container .card {
    position: relative;
    padding: 0;
    margin: 50px 20px 10px;
    border: none;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 5px 5px 5px rgb(215, 215, 215);
}


.pin_container .card_medium {
    grid-row-end: span var(--card_medium);
}

.pin_container .card_large {
    grid-row-end: span var(--card_large);
}


.pin_container .article-image {
    min-width: 100%;
    position: absolute;
    height: 50%;
    left: 50%;
    transform: translateX(-50%)
}

.article-info {
    position: absolute;
    bottom: 0;
    padding: 15px;
    color: #1b4965;
    font-weight: bold
}



@media (max-width: 1000px) {
    .mapbox-map {
        margin: 0;
    }

    .App {
        margin-top: 80px
    }

    .navbar .navbar-brand img {
        height: 55px
    }
}

@media (max-width: 500px) {
    .sidebar {
        width: 80%;
        top: 5px;
        bottom: 5px;
    }

    .sidebar.show {
        left: 5px;
    }
}

.white-space {
    height: 1px;
    background-color: white;
}


.load-more {
    text-align: center;
    color: #1b4965;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
}

.color-direction {
    position: absolute;
    float: right;
    right: 80px;
    width: 120px;
    height: 100px;
    top: 10px;
    border: thin rgb(227, 225, 225);
    border-radius: 5px;
    background-color: white;
    
}

.news-articles h3 {
    padding-top: 20px;
    padding-left: 170px
}


.footer {
    height: 50px;
    line-height: 50px;
    overflow-y: hidden;
    background-color: #0078c1;
    color: white;
    text-align: center;
    position: fixed;
    bottom: 0;
}

.footer a {
    color: white;
    text-decoration: none;
}